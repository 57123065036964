import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemAvatar,
  Stack,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';

import { useStyles } from './styles';
import { useEffect, useRef } from 'react';
import { useGetConversation } from '@features/Admin/Campaign/hooks/useGetConversation';
import { useTerminateConversation } from '@features/Admin/Campaign/hooks/useTerminateConversation';

interface MonitorCampaignProps {
  showDialogFlag: boolean;
  closeDialog: () => void;
  phoneNumber: string;
  callDuration: string;
  callsStatus: string;
  recipientId: string;
  answered: boolean;
}

export const MonitorCampaignPopup: React.FC<MonitorCampaignProps> = ({
  showDialogFlag,
  closeDialog,
  phoneNumber,
  callDuration,
  callsStatus,
  answered,
  recipientId,
}: MonitorCampaignProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { conversationData, initialLoading: loading } = useGetConversation(recipientId);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesBoxRef = useRef<HTMLDivElement | null>(null);

  const { terminate } = useTerminateConversation(recipientId);

  useEffect(() => {
    if (!messagesBoxRef.current || !messagesEndRef.current) return;

    const { clientHeight, scrollTop, scrollHeight } = messagesBoxRef.current;
    const isNearBottom = scrollHeight - 100 <= clientHeight + scrollTop;

    if (isNearBottom) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationData]);

  useEffect(() => {
    if (!messagesEndRef.current) return;

    if (!loading) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading]);

  const onClickTerminateCall = () => {
    terminate();
    closeDialog();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={() => closeDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        {phoneNumber}
        {/* <FlagOutlinedIcon color="primary" /> */}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.dialogGridContent}>
          <Grid item xs={12} mt="20px">
            {callsStatus === 'completed' && (
              <Stack direction="row" justifyContent="space-between">
                <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
                  Duration:
                </Typography>
                <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
                  {callDuration}
                </Typography>
              </Stack>
            )}
          </Grid>

          <Grid item xs={12} mt="10px">
            {!loading && conversationData.data.length !== 0 ? (
              <Box className={classes.chatBox}>
                <Box ref={messagesBoxRef} className={classes.chatBoxMessages}>
                  {conversationData.data.map((messageItem, index) =>
                    messageItem.actor === 'agent' ? (
                      <Box
                        key={index}
                        mb={1}
                        display="flex"
                        alignItems="flex-end"
                        flexDirection="row-reverse">
                        <Box alignItems="flex-end" display="flex" flexDirection={'column'}>
                          <Typography variant="body2" color="grey.400" mb={1}>
                            {timeAgoCalculation(messageItem.createdAt)}
                          </Typography>
                          <Box
                            mb={1}
                            sx={{
                              p: 1,
                              backgroundColor: 'white',
                              ml: 'auto',
                              maxWidth: '320px',
                            }}>
                            {messageItem.message}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box display="flex" key={index}>
                        <ListItemAvatar>
                          <Avatar
                            src="/src/shared/assets/images/profile/user-1.jpg"
                            sx={{ width: 40, height: 40 }}
                          />
                        </ListItemAvatar>
                        <Box>
                          <Typography variant="body2" color="grey.400" mb={1}>
                            {`${phoneNumber}, ${timeAgoCalculation(messageItem.createdAt)}`}
                          </Typography>

                          <Box
                            mb={2}
                            sx={{
                              p: 1,
                              backgroundColor: 'white',
                              mr: 'auto',
                              maxWidth: '320px',
                            }}>
                            {messageItem.message}
                          </Box>
                        </Box>
                      </Box>
                    ),
                  )}
                  <div ref={messagesEndRef} />
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ textAlign: 'center' }}>
                    {t('campaignPage.emptyConversationHistory')}
                  </Typography>
                )}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} mt="20px">
            {['calling'].includes(callsStatus) && (
              <Button
                onClick={onClickTerminateCall}
                startIcon={<PhonePausedOutlinedIcon />}
                variant="text"
                color="error"
                disabled={!answered}
                fullWidth>
                {t('campaignPage.terminateACall')}
              </Button>
            )}

            {['completed'].includes(callsStatus) && (
              <Button
                onClick={closeDialog}
                startIcon={<PhonePausedOutlinedIcon />}
                variant="text"
                color="success"
                fullWidth>
                Call is completed (click to close modal)
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

const timeAgoCalculation = (createdAt: string) => {
  const pastTime = new Date(createdAt);
  const now = new Date();
  const timeDifference: number = now.getTime() - pastTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return `${days} day(s) ago`;
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return `1 hour ago`;
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else if (minutes === 1) {
    return `1 minute ago`;
  } else {
    return `just now`;
  }
};
