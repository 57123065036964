export const getCallDuration = (answeredAt: string, terminatedAt: string) => {
  const answeredAtTime = new Date(answeredAt).getTime();
  const terminatedAtTime = new Date(terminatedAt).getTime();

  const durationSec = Math.floor((terminatedAtTime - answeredAtTime) / 1000);
  const hours = String(Math.floor(durationSec / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((durationSec % 3600) / 60)).padStart(2, '0');
  const seconds = String(durationSec % 60).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const getMedianCallDurationInMin = (callDuration?: number | null): string => {
  if (!callDuration) {
    return 'N/A';
  }
  const roundedValue = Math.round(callDuration);

  const hours = String(Math.floor(roundedValue / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((roundedValue % 3600) / 60)).padStart(2, '0');
  const secs = String(roundedValue % 60).padStart(2, '0');

  return `${hours}:${minutes}:${secs}`;
};
