import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useCreateCampaignControllerCreateCampaignMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import { CreateCampaignFormValue } from '../model/type';
import { useNavigate } from 'react-router-dom';
import { initialSchedule } from '@shared/constants/initialSchedule';
import { extractLeadErrors } from '../utils/extract-lead-errors';
import { CampaignFieldsError } from '@shared/types/admin/campaign';
import { removeDuplicatePhones } from '../utils/remove-duplicates-phone';

interface useCreateCampaign {
  setCampaignFieldError: React.Dispatch<React.SetStateAction<CampaignFieldsError>>;
}

export const useCreateCampaign = ({ setCampaignFieldError }: useCreateCampaign) => {
  const [useCreateCampaign, { isLoading }] = useCreateCampaignControllerCreateCampaignMutation();
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const { handleSubmit, setValue, reset, ...formMethods } = useForm<CreateCampaignFormValue>({
    mode: 'all',
    defaultValues: {
      title: '',
      agentId: '',
    },
  });

  const navigate = useNavigate();

  const handleLocationMain = () => {
    navigate('/');
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      useCreateCampaign({
        createCampaignRequestDto: {
          title: data.title,
          description: 'test',
          retryIntervalInMinutes: data.retryIntervalInMinutes,
          retryMaxNumber: data.retryMaxNumber,
          retriesPerDay: data.retriesPerDay,
          agentId: data.agentId,
          createdAt: data.createdAt,
          updatedAt: new Date().toISOString(),
          leadList: removeDuplicatePhones(data.leadList),
          callSchedule: data.callSchedule || initialSchedule,
          followUpTemplate: data.followUpTemplate,
        },
      })
        .unwrap()
        .then(async (payload) => {
          setResponseLoadingFlag(true);
          await refetch();
          handleLocationMain();
          setResponseMsg({
            message: 'Successfully created',
            type: 'success',
          });
        })
        .catch((error) => {
          if (error.status == 400) {
            const leadErrors = extractLeadErrors(error?.data || {});

            if (leadErrors) {
              setCampaignFieldError({
                leadErrors,
              });
            }

            if (error.data.retriesPerDay) {
              setCampaignFieldError((prevState) => ({
                ...prevState,
                retriesPerDay: error.data.retriesPerDay[0],
              }));
            }

            if (error.data.retryIntervalInMinutes) {
              setCampaignFieldError((prevState) => ({
                ...prevState,
                retryIntervalInMinutes: error.data.retryIntervalInMinutes[0],
              }));
            }

            if (error.data.retryMaxNumber) {
              setCampaignFieldError((prevState) => ({
                ...prevState,
                retryMaxNumber: error.data.retryMaxNumber[0],
              }));
            }
          }
          setResponseMsg({
            message: 'Something went wrong',
            type: 'error',
          });
        });
      setResponseLoadingFlag(false);
    } catch (error) {
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue,
    reset,
    onSubmit,
    isLoading,
    responseMsg,
    setResponseMsg,
    responseLoadingFlag,
    ...formMethods,
  };
};
