import {
  ConversationLogDto,
  useGetConversationMessageControllerGetMessageQuery,
} from '@shared/services/conversationApiService/apiService';
import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

const MESSAGES_LIMIT_PER_REQUEST = 20;

export const useGetConversation = (recipientId: string) => {
  const [pollingConversationInterval, setPollingConversationInterval] = useState(2000);
  const [cursor, setCursor] = useState<string | null>(null);
  const [accumulatedData, setAccumulatedData] = useState<ConversationLogDto[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const uniqueById = (array: ConversationLogDto[]) => {
    const seen = new Set();
    return array.filter((item) => {
      const isDuplicate = seen.has(item.id);
      seen.add(item.id);
      return !isDuplicate;
    });
  };

  const { data: conversationData, refetch: conversationRefetch } =
    useGetConversationMessageControllerGetMessageQuery(
      recipientId
        ? {
            conversationId: recipientId,
            limit: MESSAGES_LIMIT_PER_REQUEST,
            cursor: cursor!,
          }
        : skipToken,
    );

  useEffect(() => {
    if (conversationData?.data) {
      setAccumulatedData((prev) => [...prev, ...conversationData.data]);
      if (conversationData.meta.hasMore) {
        setCursor(conversationData.meta.cursor);
      } else {
        setInitialLoading(false);
      }
    }
  }, [conversationData]);

  const filteredAccumulatedData = uniqueById(accumulatedData);

  return {
    conversationData: { data: filteredAccumulatedData, meta: conversationData?.meta },
    conversationRefetch,
    pollingConversationInterval,
    setPollingConversationInterval,
    initialLoading,
  };
};
