import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { capitalize } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconTrash, IconExternalLink, IconArrowUp, IconArrowDown } from '@tabler/icons-react';

import {
  CampaignWithDetailedInfoDto,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useStopCampaignRunControllerStopCampaignRunMutation,
  CampaignRunStatusType,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import BlankCard from '@shared/components/shared/BlankCard';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { CampaignListProps, SortingCampaignCoulmns } from '@shared/types/admin/campaign';
import CustomAlert from '@shared/components/alert/alert';

import { useStyles } from './styles';
import CampaignStatusButton, { CampaignStatus } from './CampaignStatusButton/CampaignStatusButton';
import Spinner from '@shared/components/spinner/Spinner';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';

export const CampaignList: React.FC<CampaignListProps> = ({
  setLoading,
  campaignList,
  onCampaignStatusUpdate,
}: CampaignListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigator = useNavigate();
  const [useDeleteCampaign] = useDeleteCampaignControllerDeleteCampaignMutation();

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCreateCampaignRunControllerCreateCampaignRunMutation();
  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useStopCampaignRunControllerStopCampaignRunMutation();

  const [campaigns, setCampaigns] = useState<CampaignWithDetailedInfoDto[]>([]);

  const [sortConfig, setSortConfig] = useState({
    title: true,
    status: true,
    totalLeads: true,
    processedNumbers: true,
    pendingLeadsCount: true,
    reachedLeadsCount: true,
    unreachedLeadsCount: true,
    plannedRecallCount: true,
  });

  const [showDialog, setShowDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [deleteId, setDeleteId] = useState<string | null>();
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  useEffect(() => {
    let sortedCampaigns = [...campaignList].sort((a, b) => {
      const dateA = a.updatedAt || a.createdAt;
      const dateB = b.updatedAt || b.createdAt;
      return dateB.localeCompare(dateA);
    });

    setCampaigns(sortedCampaigns);
  }, [campaignList]);

  const handleSort = (field: SortingCampaignCoulmns) => {
    // Toggle sort order for the specific field
    setSortConfig((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));

    const sortedCampaigns = sortArray(campaigns, field, sortConfig[field]);
    setCampaigns(sortedCampaigns);
  };

  const sortArray = (
    arr: CampaignWithDetailedInfoDto[],
    field: SortingCampaignCoulmns,
    ascending = true,
  ) => {
    if (field === 'status') {
      const statusOrder: CampaignRunStatusType[] = ['active', 'inactive', 'finishing'];

      return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
        const statusA = a.campaignRun?.status || 'inactive';
        const statusB = b.campaignRun?.status || 'inactive';

        return ascending
          ? statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
          : statusOrder.indexOf(statusB) - statusOrder.indexOf(statusA);
      });
    }

    if (field === 'processedNumbers') {
      return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
        const processedA = a.unreachedLeadsCount + a.reachedLeadsCount;
        const processedB = b.unreachedLeadsCount + b.reachedLeadsCount;
        if (processedA < processedB) return ascending ? -1 : 1;
        if (processedA > processedB) return ascending ? 1 : -1;
        return 0;
      });
    }

    return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
      if (a[field] < b[field]) return ascending ? -1 : 1;
      if (a[field] > b[field]) return ascending ? 1 : -1;
      return 0;
    });
  };

  const statusBadge = (status?: CampaignRunStatusType) => {
    const colorMap = {
      active: theme.palette.success.light,
      inactive: theme.palette.error.light,
      finishing: theme.palette.warning.light,
    };

    return colorMap?.[status!] || theme.palette.error.light;
  };

  const handleManagedStartStop = async (id: string, campaignStatus: CampaignStatus) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      await useRunCampaign({ id });
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
    }

    onCampaignStatusUpdate();
  };

  const handleDeleteBtn = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setShowDialog(true);
    if (selectedRowId) {
      setDeleteId(selectedRowId);
    }
    setAnchorEl(null);
  };

  const deleteCampagin = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoading(true);
      const response = await useDeleteCampaign({
        id: deleteId,
      });
      if ('error' in response) {
        setResponseMsg({
          message: 'Failed to delete agent!',
          type: 'error',
        });
      } else {
        setResponseMsg({
          message: 'Deleted agent successfully.',
          type: 'success',
        });
      }
    }
    setDeleteId('');
    setLoading(false);
  };

  const handleMoreBtn = (event: any, rowId: string) => {
    setAnchorEl(event.currentTarget);

    setSelectedRowId(rowId);
  };

  const handlePopoverAction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (selectedRowId) {
      handleRowClick(selectedRowId);
    }
    handleClosePopover();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (rowId: string) => {
    navigator(`/admin/campaign/edit?id=${rowId}`);
  };

  if (responseLoadingFlag) {
    return <Spinner />;
  }

  return (
    <BlankCard className={classes.campaign}>
      <Box className={classes.campaign_header}>
        <Box className={classes.campaign_header_title}>
          <Typography fontSize="20px">{t('adminMainPage.campaigns.title')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignContent: 'start', alignItems: 'start' }}>
          <Tooltip title={t('adminMainPage.operation.addCampaign')} arrow>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigator(AuthenticatedPath.CREATE_CAMPAIGN);
              }}>
              {t('adminMainPage.operation.addCampaign')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table className={classes.campaign_table}>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort('title')}
                className={classes.campaign_table_first_cell}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.campaignName')}
                  </Typography>
                  {sortConfig.title ? <IconArrowDown size="12" /> : <IconArrowUp size="12" />}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('status')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.status')}
                  </Typography>
                  {sortConfig.status ? <IconArrowDown size="12" /> : <IconArrowUp size="12" />}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('totalLeads')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.totalNumber')}
                  </Typography>
                  {sortConfig.totalLeads ? <IconArrowDown size="12" /> : <IconArrowUp size="12" />}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('processedNumbers')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.processedNumber')}
                  </Typography>
                  {sortConfig.processedNumbers ? (
                    <IconArrowDown size="12" />
                  ) : (
                    <IconArrowUp size="12" />
                  )}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('pendingLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.ongoingCalls')}
                  </Typography>
                  {sortConfig.pendingLeadsCount ? (
                    <IconArrowDown size="12" />
                  ) : (
                    <IconArrowUp size="12" />
                  )}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('reachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.reachedCall')}
                  </Typography>
                  {sortConfig.reachedLeadsCount ? (
                    <IconArrowDown size="12" />
                  ) : (
                    <IconArrowUp size="12" />
                  )}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('unreachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.unreachedCall')}
                  </Typography>
                  {sortConfig.unreachedLeadsCount ? (
                    <IconArrowDown size="12" />
                  ) : (
                    <IconArrowUp size="12" />
                  )}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('plannedRecallCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.plannedRetries')}
                  </Typography>
                  {sortConfig.plannedRecallCount ? (
                    <IconArrowDown size="12" />
                  ) : (
                    <IconArrowUp size="12" />
                  )}
                </Box>
              </TableCell>

              <TableCell>
                <Typography variant="h6" className={classes.campagin_table_th} align="center">
                  {t('adminMainPage.operation.startStop')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" className={classes.campagin_table_th} align="center">
                  {t('adminMainPage.operation.action')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((row) => (
              <TableRow
                className={classes.campaign_table_row}
                key={row.id}
                hover
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row.id);
                }}>
                <TableCell className={classes.campaign_table_first_cell}>
                  <Typography variant="subtitle1">{row.title}</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    sx={{
                      backgroundColor: statusBadge(row.campaignRun?.status),
                    }}
                    size="small"
                    label={capitalize(row.campaignRun?.status || 'inactive')}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.totalLeads}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">
                    {row.unreachedLeadsCount + row.reachedLeadsCount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.pendingLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.reachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.unreachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.plannedRecallCount}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Status Campaign">
                    <CampaignStatusButton
                      onClick={handleManagedStartStop}
                      disabled={isRunningLoading || isStoppingLoading}
                      campaign={row}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="More actions">
                    <IconButton
                      aria-describedby={row.id.toString()}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMoreBtn(event, row.id);
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {selectedRowId === row.id && (
                  <Popover
                    id={row.id.toString()}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box className={classes.agent_table_popover}>
                      <IconButton
                        className={classes.agent_table_popover_button}
                        onClick={handlePopoverAction}>
                        <IconExternalLink size="24px" />
                        <Typography>{t('adminMainPage.operation.open')}</Typography>
                      </IconButton>
                      <IconButton
                        className={classes.agent_table_popover_button}
                        onClick={handleDeleteBtn}>
                        <IconTrash size="24px" color="#FA896B" />
                        <Typography color="#FA896B">
                          {t('adminMainPage.operation.delete')}
                        </Typography>
                      </IconButton>
                    </Box>
                  </Popover>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {responseMsg.message ? (
          <CustomAlert message={responseMsg.message} type={responseMsg.type} />
        ) : (
          ''
        )}
      </div>
      <ConfirmDeleteDialog
        open={showDialog}
        onClose={setShowDialog}
        dialogTitle={'dialog.title3'}
        dialogContentText={'dialog.content3'}
        showDialog={setShowDialog}
        confirmDelete={deleteCampagin}
      />
    </BlankCard>
  );
};
