import {
  useEditCampaignControllerEditCampaignMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateCampaignFormValue } from '../model/type';
import { useNavigate } from 'react-router-dom';
import { extractLeadErrors } from '../utils/extract-lead-errors';
import { CampaignFieldsError } from '@shared/types/admin/campaign';
import { removeDuplicatePhones } from '../utils/remove-duplicates-phone';

interface useEditCampaign {
  setCampaignFieldError: React.Dispatch<React.SetStateAction<CampaignFieldsError>>;
  leadListChanged: boolean;
}

export const useEditCampaign = ({ setCampaignFieldError, leadListChanged }: useEditCampaign) => {
  const [useEditCampaign] = useEditCampaignControllerEditCampaignMutation();
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const {
    handleSubmit,
    setValue: setFormValue,
    reset,
    ...formMethods
  } = useForm<CreateCampaignFormValue>({
    mode: 'all',
    defaultValues: {
      title: '',
      agentId: '',
    },
  });

  const navigate = useNavigate();

  const handleLocationMain = () => {
    navigate('/');
  };

  const onSubmit = handleSubmit(async (data) => {
    setResponseMsg({
      message: '',
      type: 'success',
    });
    // to pass only new led lists
    const leadList = leadListChanged
      ? data.leadList.filter((item) => {
          return !item.id;
        })
      : [];

    try {
      useEditCampaign({
        editCampaignRequestDto: {
          title: data.title,
          description: 'test',
          retryIntervalInMinutes: data.retryIntervalInMinutes,
          retryMaxNumber: data.retryMaxNumber,
          retriesPerDay: data.retriesPerDay,
          agentId: data.agentId,
          createdAt: data.createdAt,
          updatedAt: new Date().toISOString(),
          leadList: leadListChanged ? removeDuplicatePhones(leadList) : undefined,
          callSchedule: data.callSchedule,
          followUpTemplate: data.followUpTemplate,
        },
        id: data.id,
      })
        .unwrap()
        .then(async () => {
          setResponseLoadingFlag(true);
          await refetch();
          handleLocationMain();
          setResponseMsg({
            message: 'Successfully created',
            type: 'success',
          });
        })
        .catch((error) => {
          const leadErrors = extractLeadErrors(error?.data || {});

          setCampaignFieldError((prevState) => ({
            ...prevState,
            editErrorMessage: error.data.message,
            leadErrors: error.status === 400 ? leadErrors : prevState.leadErrors,
          }));

          setResponseMsg({
            message: 'Something went wrong',
            type: 'error',
          });
        });
      setResponseLoadingFlag(false);
    } catch (error) {
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue: setFormValue,
    reset,
    onSubmit,
    setResponseMsg,
    responseMsg,
    responseLoadingFlag,
    ...formMethods,
  };
};
